/**
 *
 * These styles are solely for adding [background images] or
 * [background colors] to blocks.
 *
 */

export default {
    heroContainer: {
        position: `relative`,
        pt: [6, 6],
        background: t => `linear-gradient(
          180deg,
            ${t.colors.beta},
          ${t.colors.betaDark} 
        )`
    }
}
